const filterRender = (val) => {
  if (!val && val !== 0) {
    return '--';
  }

  return val;
};

export function setShipTable () {
  const shipColumns = [
    {
      field: 'goodsCode',
      title: '商品编码',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      field: 'goodsName',
      title: '商品名称',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      field: 'specsNo',
      title: '规格型号',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.specsNo);
      },
    },
    {
      field: 'brandName',
      title: '品牌名称',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      field: 'producer',
      title: '生产商',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      field: 'isMedichair',
      title: '是否医疗件',
      tooltip: true,
      minWidth: '110px',
      formatter: ({ row }) => {
        return row.isMedichair === 1 ? '是' : '否';
      },
    },
    {
      field: 'unitOfMsment',
      title: '计量单位',
      tooltip: true,
      minWidth: '100px',
      formatter: ({ row }) => {
        return filterRender(row.unitOfMsment);
      },
    },
    {
      field: 'supName',
      title: '供应商名称',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.supName);
      },
    },
    {
      field: 'noShippedNum',
      title: '未发货数量',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.noShippedNum);
      },
    },
    {
      field: 'orderCode',
      title: '销售单号',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.orderCode);
      },
    },
  ];

  const item = {
    field: 'consumeNum',
    title: '本次发货数量',
    tooltip: true,
    minWidth: '150px',
    fixed: 'right',
  };

  if (this.isDirect === 1) {
    item.slots = {
      default: ({row, rowIndex}, h) => {
        return [h('span', {
          class: ['num', row.warn ? 'warn' : ''],
          on: {
            click: () => {
              this.handleDeliveryGoods(row, rowIndex);
            },
          },
        }, row.consumeNum)];
      },
    };
  } else if (this.isDirect === 2) {
    item.editRender = { name: '$input', props: { type: 'integer', min: 0, max: 99999999999, placeholder: '请输入销售数量' } };
  }

  shipColumns.push(item);

  return shipColumns;
}

export function setDeliveryGoodsColumn (isMedichair) {
  const frontCols = [
    {
      tooltip: true,
      title: '全部',
      minWidth: '100px',
      align: 'center',
      width: '',
      fixed: 'left',
      type: 'checkbox',
      prop: '',
    },
    {
      field: 'goodsCode',
      title: '商品编码',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      field: 'goodsName',
      title: '商品名称',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      field: 'brandName',
      title: '品牌名称',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      field: 'specsNo',
      title: '规格型号',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.specsNo);
      },
    },
    {
      field: 'producer',
      title: '生产商',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      field: 'deliveryNum',
      title: '发货数量',
      tooltip: true,
      minWidth: '100px',
      formatter: () => {
        return '1';
      },
    },
    {
      field: 'productionDate',
      title: '生产日期',
      minWidth: '130px',
      editRender: { name: '$input', props: { type: 'date', placeholder: '请选择生产日期' } },
    },
    {
      field: 'expiryDate',
      title: '失效日期',
      minWidth: '130px',
      editRender: { name: '$input', props: { type: 'date', placeholder: '请选择失效日期' } },
    },
  ];

  const endCols = [
    {
      field: 'isMedichair',
      title: '是否医疗件',
      tooltip: true,
      minWidth: '110px',
      formatter: ({ row }) => {
        return row.isMedichair === 1 ? '是' : '否';
      },
    },
    {
      field: 'certificateNumber',
      title: '注册证号',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.certificateNumber);
      },
    },
    {
      title: '操作',
      fixed: 'right',
      minWidth: '100',
      slots: {
        default: ({rowIndex}, h) => {
          return [h('span', {
            class: 'num',
            on: {
              click: () => {
                let type = rowIndex ? 'reduce' : 'add';
                this.operateDeliveryGoods(type, rowIndex);
              },
            },
          }, !rowIndex ? '+' : '-')];
        },
      },
    },
  ];

  let columns = [];

  if (isMedichair === 1) {
    columns = [
      ...frontCols,
      {
        field: 'productionBatchNumber',
        title: '生产批次号',
        minWidth: '130px',
        editRender: { name: '$input', props: { placeholder: '请输入生产批次号' } },
      },
      {
        field: 'productionSerialNumber',
        title: '生产序列号',
        minWidth: '130px',
        editRender: { name: '$input', props: { placeholder: '请输入生产序列号' } },
      },
      ...endCols,
    ];
  } else {
    columns = [
      ...frontCols,
      ...endCols,
    ];
  }

  columns.forEach(v => {
    if (!v.minWidth) {
      v.minWidth = `${v.title.length * 16 + 20}px`;
    }
  });

  return columns;
}
