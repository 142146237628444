import server from '@/request';

const prefix = '/xiaozi-seller/wireless/order';

// 提货订单列表(分页)
export const queryPage = (data = {}) => {
  return server.post(`${prefix}/queryPage`, data);
};

// 提货订单详情
export const queryDetail = (data = {}) => {
  return server.post(`${prefix}/details`, data);
};

// 订单数量统计
export const queryStateInfo = (data = {}) => {
  return server.post(`${prefix}/queryStateInfo`, data);
};

// 物流公司列表
export const customerPage = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/customer/page', data);
};

// 物流公司列表
export const getSupplierPage = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/supplier/page', data);
};

// 发货详情
export const sellerDeliveAdd = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/notice/sellerDeliveAdd', data);
};

// 订单发货
export const deliver = (data = {}) => {
  return server.post('/xiaozi-seller/wireless/notice/deliver', data);
};

