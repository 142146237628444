const filterRender = (val) => {
  if (!val && val !== 0) {
    return '--';
  }

  return val;
};

export function setDetailTable () {
  const detailTableCols = [
    {
      title: '商品编码（sku）',
      field: 'goodsCode',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      title: '商品名称',
      field: 'goodsName',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      title: '规格',
      field: 'specification',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.specification);
      },
    },
    {
      title: '型号',
      field: 'model',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.model);
      },
    },
    {
      title: '所属品牌',
      field: 'brandName',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      title: '计量单位',
      field: 'unitOfMsment',
      tooltip: true,
      minWidth: '100px',
      formatter: ({ row }) => {
        return filterRender(row.unitOfMsment);
      },
    },
    {
      title: '销售数量',
      field: 'currentBillingNum',
      tooltip: true,
      minWidth: '110px',
      formatter: ({ row }) => {
        return filterRender(row.currentBillingNum);
      },
    },
    {
      title: '销售单价（含税）',
      field: 'unitPrice',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return filterRender(row.unitPrice);
      },
    },
    {
      title: '生产商',
      field: 'producer',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      title: '厂家编码',
      field: 'factoryCode',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.factoryCode);
      },
    },
    {
      title: '保修期',
      field: 'period',
      tooltip: true,
      minWidth: '150px',
      formatter: ({ row }) => {
        return `${row.period || ''}${row.periodUnit || ''}` || '--';
      },
    },
    {
      title: '是否医疗件',
      field: 'isMedichair',
      tooltip: true,
      minWidth: '110px',
      formatter: ({ row }) => {
        return row['isMedichair'] === 1 ? '是' : '否';
      },
    },
    {
      title: '产品注册证/备案凭证',
      field: 'regNo',
      tooltip: true,
      minWidth: '180px',
      formatter: ({ row }) => {
        return filterRender(row.regNo);
      },
    },
  ];

  return detailTableCols;
}
